import { useState } from "react";
import NavigationBar from "../src/components/atoms/navBar";
import "./App.css";
import Chatbot from "./components/atoms/chatbot";
import chatbotImgG from "./assets/chatbotImgG.png";
import chatbotCerrar from "./assets/CerrarChatBot.png";
import { Box, Modal } from "@mui/material";
import Careers from "./components/pages/careers";
import AboutUs from "./components/pages/aboutUs";
import Details from "./components/pages/details";
import Home from "./components/pages/home";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PrivacyPolicy from "./pages/privacy-policy/privacy";

function App() {
  const [goToPortafolio, setGoToPortafolio] = useState({
    goToSelectionRef: null,
    scrollTo: null,
  });
  function scrollToPortafolio(goToSelectionRef, scrollTo) {
    setGoToPortafolio({
      goToSelectionRef: goToSelectionRef,
      scrollTo: scrollTo,
    });
  }
  const style = {
    borderColor: "none",
    position: "absolute",
    top: "50%",
    left: "80%",
    transform: "translate(-50%, -50%)",

    bgcolor: "transparent",
    border: "0px",
    boxShadow: 24,
    p: 0,
  };
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div className="App">
      <div className="image-contents image-container ">
        <div style={{ display: "flex", justifyContent: "start" }}>
          {open ? (
            <img
              onClick={handleClose}
              className="chatBotMenu icon-container-home"
              src={chatbotCerrar}
              width="50%"
              alt=""
            />
          ) : (
            <img
              onClick={handleOpen}
              className="chatBotMenu icon-container-home"
              src={chatbotImgG}
              width="50%"
              alt=""
            />
          )}
        </div>
      </div>

      <Modal
        style={{ zIndex: "3" }}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Chatbot />
        </Box>
      </Modal>

      <BrowserRouter>
        <NavigationBar
          goToSelectionRef={goToPortafolio.goToSelectionRef}
          scrollTo={goToPortafolio.scrollTo}
        />
        <Routes>
          <Route
            path="/:section"
            element={<Home scrollToPortafolio={scrollToPortafolio} />}
          />
          <Route
            path="/home/:section"
            element={<Home scrollToPortafolio={scrollToPortafolio} />}
          />
          <Route path="/careers" element={<Careers />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/details/:id" element={<Details />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />

          <Route
            path="*"
            element={<Home scrollToPortafolio={scrollToPortafolio} />}
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
