import React from "react";
import "./aboutUsEquipo.css";
import { Container, Row, Col, Image } from "react-bootstrap";
import aUP5_creative from "./../../../assets/Creative.svg";
import aUP5_lineTitle from "./../../../assets/aUP5Vector1.svg";
import aUP5_image from "./../../../assets/aUE_image_7.svg";
import aUP5_image_2 from "./../../../assets/aUP5_image2.svg";

function AboutUsEquipo() {
  return (
    <div className="aUEquipoBackGround">
      <Container>
        <Row>
          <Col>
            <div className="aUETitle">
              <h1 className="aUETitleText">Equipo</h1>
              <Image src={aUP5_lineTitle} className="aUETitleLine" fluid />
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="aUEPart1">
            <img src={aUP5_image_2} className="aUEimage_2" alt="" />
            <img src={aUP5_creative} className="aUE_Creative" alt="" />
            <br />
            <br />
            <h2 className="aUET2">
              SOMOS EL PODER <br />
              DE LA UNIÓN
            </h2>

            <br />
            <p className="aUET3">
              Somos creadores de soluciones inusuales, efectivas y de alto
              impacto, con talento comprometido en la generación de valor para
              los clientes capaz de inspirar un cambio en las personas.
            </p>
          </Col>
          <Col className="aUEPart2">
            <img src={aUP5_image} className="aUEimage" alt="" />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default AboutUsEquipo;
