import React from "react";
import pdfFile from "../../assets/privacypolicy-GPD.pdf";

export default function PrivacyPolicy() {
  return (
    <div>
      <embed src={pdfFile} type="application/pdf" height={960} width={"100%"} />
    </div>
  );
}
