export const formatPhoneNumber = (phoneNumber) => {
  // Eliminar todos los caracteres que no sean números del número de teléfono
  const phoneNumberOnlyDigits = phoneNumber.replace(/\D/g, "");

  // Aplicar formato al número de teléfono (ejemplo: (123) 456-7890)
  let formattedPhoneNumber = "";

  if (phoneNumberOnlyDigits.length > 0) {
    formattedPhoneNumber += "(";
    formattedPhoneNumber += phoneNumberOnlyDigits.slice(0, 3);
    formattedPhoneNumber += ") ";
    formattedPhoneNumber += phoneNumberOnlyDigits.slice(3, 6);

    if (phoneNumberOnlyDigits.length > 6) {
      formattedPhoneNumber += "-";
      formattedPhoneNumber += phoneNumberOnlyDigits.slice(6, 10);
    }
  }

  // Eliminar el formato completo si no hay dígitos en el número de teléfono
  if (formattedPhoneNumber === "()" || formattedPhoneNumber === "() ") {
    formattedPhoneNumber = "";
  }

  return formattedPhoneNumber;
};
