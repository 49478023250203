import React from "react";
import "./aboutUsFooter.css";
import { useDispatch } from "react-redux";
import { changeShowForm } from "../../../store/reducers/thunk";
import { Link, useNavigate } from "react-router-dom";
import lineFooter from "../../../assets/footerLine.png";
import { Container, Row, Col } from "react-bootstrap";
import PD_G_logo from "../../../assets/PD_G_logo.svg";
import LinkedInIcon from "../../../assets/LINKDIN.svg";
import InstagramIcon from "../../../assets/instagram.svg";
import YouTubeIcon from "../../../assets/youtube.svg";

function AboutUsFooter({ scrollTo, goToSelectionRef }) {
  const dispatch = useDispatch();

  function showFrom() {
    dispatch(changeShowForm(true));
  }

  const navigate = useNavigate();

  return (
    <footer className="mt-auto py-3 bg-black aUFBackGround">
      <div className="footerContainerDown">
        <Container className="footerContainerUp">
          <Row className="justify-content-md-center">
            <Col md={{ span: 6, offset: 0 }}>
              <h2 className="aUFt1">
                Únete{" "}
                <span className="aUFt2">
                  a <br></br>
                </span>
                <span className="aUFt2">este </span> equipo
              </h2>

              <button
                className="aUFbutton"
                onClick={() => {
                  navigate("/careers", { replace: true });
                }}
              >
                Empleos
              </button>
            </Col>
          </Row>
        </Container>

        <div className="lineFooter">
          <img src={lineFooter} width="100%" alt="" />
        </div>

        <Container className="footerContainerPadding">
          <Row className="footerContainerPaddingTD">
            <Col xs={12} md={4}>
              <div className="textFooterLinkSocialPages">
                <div className="textFooterTitleSocialPages">¡SIGUENOS!</div>

                <a
                  className="aUFBackGround textFooterContentSocialPages"
                  href="https://www.linkedin.com/company/grupopenadefillo/"
                >
                  LINKEDIN
                </a>

                <br />

                <a
                  className="aUFBackGround textFooterContentSocialPages"
                  href="https://www.instagram.com/pena.defillo/"
                >
                  INSTAGRAM
                </a>

                <br />

                <a
                  className="aUFBackGround textFooterContentSocialPages"
                  href="https://www.youtube.com/@grupopenadefillo"
                >
                  YOUTUBE
                </a>
              </div>
            </Col>

            <Col
              className="footerNav"
              xs={12}
              md={8}
              style={{ textAlign: "right" }}
            >
              <img className="PD_G_logo" src={PD_G_logo} alt="" />

              <Link
                className="aUFBackGround textFooterLinkPages"
                to="/home/portafolio"
              >
                PORTAFOLIO
              </Link>

              <Link className="footerColor textFooterLinkPages" to="/careers">
                {""}EMPLEOS{" "}
              </Link>

              <Link
                className="footerColor textFooterLinkPages"
                onClick={() => scrollTo(goToSelectionRef)}
              >
                {""}SOBRE NOSOTROS{" "}
              </Link>

              <Link
                className="aUFBackGround textFooterLinkPages"
                onClick={() => showFrom()}
              >
                {" "}
                CONTÁCTANOS{" "}
              </Link>

              <Link
                className="aUFBackGround textFooterLinkPages"
                to="https://goo.gl/maps/sHnehVLCdqHvbGyH8"
                onClick={() => showFrom()}
              >
                {" "}
                UBICACIÓN{" "}
              </Link>
            </Col>
          </Row>

          <Row>
            <Col className="text-center">
              <hr></hr>
              <div className="bottomFooterContent">
                <div>
                  <a href="https://www.linkedin.com/company/grupopenadefillo/">
                    <img className="socialIcon" src={LinkedInIcon} alt="" />
                  </a>

                  <a href="https://www.instagram.com/pena.defillo/">
                    <img className="socialIcon" src={InstagramIcon} alt="" />
                  </a>

                  <a href="https://www.youtube.com/@grupopenadefillo">
                    <img className="socialIcon" src={YouTubeIcon} alt="" />
                  </a>
                </div>

                <div className="dFooter">
                  <p className="copyRight">
                    &copy; Peña Defilló {new Date().getFullYear()}{" "}
                  </p>

                  <p className="dot"> • </p>

                  <li className="policy">
                    <a href="https://sharepenadefillo-my.sharepoint.com/personal/rrhh_sharepenadefillo_onmicrosoft_com/_layouts/15/onedrive.aspx?id=%2Fpersonal%2Frrhh%5Fsharepenadefillo%5Fonmicrosoft%5Fcom%2FDocuments%2FPol%C3%ADtica%20de%20privacidad%20de%20Grupo%20Pe%C3%B1a%20Defill%C3%B3%2Epdf&parent=%2Fpersonal%2Frrhh%5Fsharepenadefillo%5Fonmicrosoft%5Fcom%2FDocuments&ga=1">
                      Politica de Privacidad
                    </a>
                  </li>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </footer>
  );
}
export default AboutUsFooter;
