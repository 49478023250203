import React
from "react";
import "./carrersHome.css";
import { Button } from "react-bootstrap";
import flecha from "../../assets/Elipse.png";

function carrersHome({ scrollTo, goToSelectionRef, goToSelectionRef2 }) {
  return (
    <>
      <div className="backgroundCarrersHome">
        <h1 className="carrersHomePadding">
          <span className="carrersHomeTitleFirst">ATREVETE</span>
          <span className="carrersHomeTitleSecond"> A</span>
          <br />{" "}
          <span className="carrersHomeTitleSecond">
            {" "}
            CAMBIAR LAS <br />{" "}
            <span className="carrersHomeTitleSecond">
              REGLAS DEL JUEGO
            </span>{" "}
          </span>
        </h1>
        <span>
          <br />
          ¡En Peña Defilló{" "}
          <span className="carrersHomeDescriptionText">
            jugamos, trabajamos y ganamos!
          </span>{" "}
          <br />
          ¿Suena como el tipo de lugar en el que te gustaría <br />
          estar?{" "}
          <span className="carrersHomeDescriptionText">
            Nos encantaría conocerte.
          </span>
        </span>
        <br />
        <Button
          className="carrearButtonHome"
          onClick={() => scrollTo(goToSelectionRef)}
        >
          Ver puestos
        </Button>

        <img
          onClick={() => scrollTo(goToSelectionRef2)}
          className="flechaCarrearHome"
          src={flecha}
          width="50%"
          alt=""
        />
      </div>
    </>
  );
}

export default carrersHome;
