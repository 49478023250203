import React from "react";
import "./subHome2.css";
import barra from "../../assets/barraDivisora.png";
import brands from "../../assets/logosEmpresas.png";

function SubHome2() {
  return (
    <>
      <div className="titleContents2">
        <h2 className="title">
          {" "}
          <span className="titleDif">creadores</span> de soluciones efectivas y
          de <br /> alto impacto, con <span className="titleDif">talento</span>{" "}
          comprometido
          <br /> en la generación de valor para <br /> nuestros clientes.
        </h2>

        <div className="barraDiv">
          <img src={barra} width="100%" alt="" />
          <img className="brandsNav" src={brands} width="100%" alt="" />
        </div>
      </div>
    </>
  );
}
export default SubHome2;
