import React from "react";
import "./subHome.css";

function SubHome() {
  return (
    <>
      <div className="titleContent">
        <h2 className="title">
          creamos conexiones <span className="titleDif">innovadoras</span> para
          <br /> <span className="titleDif">impactar</span> de manera
          significativa la vida
          <br /> de las personas a través de{" "}
          <span className="titleDif">soluciones</span> <br />
          integradas de comunicación que dan
          <br /> <span className="titleDif">valor </span>a las marcas
        </h2>
      </div>
    </>
  );
}

export default SubHome;
