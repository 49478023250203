import React, { useState, useEffect } from "react";
import "./carrersAvalible.css";
import { Link } from "react-router-dom";
import rainbow from "../../assets/lineaRainbowCarrear.svg";
import { Container, Row, Col } from "react-bootstrap";

function CarrearsAvalible() {
  const [empleos, setEmpleos] = useState([]);

  useEffect(() => {
    fetch("https://cms.pruebagpd.com/api/empleos")
      .then((response) => response.json())
      .then((data) => setEmpleos(data.data))
      .catch((error) => console.log(error));
  }, []);

  const empleosPorDepartamento = {};

  empleos.forEach((empleo) => {
    if (empleo.attributes.Departamento in empleosPorDepartamento) {
      empleosPorDepartamento[empleo.attributes.Departamento].push(empleo);
    } else {
      empleosPorDepartamento[empleo.attributes.Departamento] = [empleo];
    }
  });

  const empleosList = Object.keys(empleosPorDepartamento).map(
    (departamento) => (
      <div key={departamento}>
        <h2 className="departmentTitle">{departamento || "Otros"}</h2>
        <div className="formatJob">
          <Container>
            <Row className="carrersLink">
              {empleosPorDepartamento[departamento].map((empleo, index) => (
                <Col md={6} key={empleo.id}>
                  <Link to={`/details/${empleo.id}`}>
                    <div className="job-order">
                      {(index + 1).toString().padStart(2, "0")}
                    </div>
                    <div className="job-info">
                      <h3 className="carrersTitle">
                        {empleo.attributes.Titulo}
                      </h3>
                      <p className="carrersDescription">
                        {empleo.attributes.Pais} — {empleo.attributes.Modalidad}
                      </p>
                    </div>
                  </Link>
                </Col>
              ))}
            </Row>
          </Container>
        </div>
      </div>
    )
  );

  return (
    <div className="backgroundCarrersAvalible">
      <h1 className="empleoTitle">EMPLEOS</h1>
      <h6 className="empleoDescTitle">
        Echa un vistazo a algunas de las
        <br />
        vacantes actuales.
      </h6>
      <img src={rainbow} width="7%" alt="" />
      {empleosList}
      <div style={{ paddingBottom: "20rem" }}></div>
    </div>
  );
}

export default CarrearsAvalible;
