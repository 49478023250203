import Mysection from "../molecules/mysection";
import { useRef } from "react";
import CarrersHome from "../atoms/carrersHome";
import CarrersHome2 from "../atoms/carrersHome2";
import CarrersAvalible from "../atoms/carrersAvalible";

export default function Careers() {
  const section1 = useRef();
  const section2 = useRef();
  const section3 = useRef();

  function scrollTo(section) {
    section.current.scrollIntoView({ behavior: "smooth" });
  }

  return (
    <div className="App">
      <div className={`container containerSection`}>
        <div ref={section1}>
          <Mysection
            headline={
              <CarrersHome
                scrollTo={scrollTo}
                goToSelectionRef={section3}
                goToSelectionRef2={section2}
              />
            }
            goToSectionRef={section2}
            scrollTo={scrollTo}
            showArrow={true}
          />
        </div>
        <div ref={section2}>
          <Mysection
            headline={<CarrersHome2 />}
            goToSectionRef={section3}
            scrollTo={scrollTo}
            showArrow={true}
          />
        </div>
        <div ref={section3}>
          <Mysection
            headline={<CarrersAvalible />}
            scrollTo={scrollTo}
            showArrow={true}
          />
        </div>
      </div>
    </div>
  );
}
