import Mysection from "../molecules/mysection";
import { useRef } from "react";
import AboutUsTitle from "../atoms/aboutUsParts/aboutUsTitle";
import AboutUsIndex from "../atoms/aboutUsParts/aboutUsIndex";
import AboutUsValores from "../atoms/aboutUsParts/aboutUsValores";
import AboutUsPromise from "../atoms/aboutUsParts/aboutUsPromise";
import AboutUsEquipo from "../atoms/aboutUsParts/aboutUsEquipo";
import AboutUsFooter from "../atoms/aboutUsParts/aboutUsFooter";

export default function AboutUs() {
  const section1 = useRef();
  const section2 = useRef();
  const section3 = useRef();
  const section4 = useRef();
  const section5 = useRef();
  const section6 = useRef();

  function scrollTo(section) {
    section.current.scrollIntoView({ behavior: "smooth" });
  }

  return (
    <div className="App">
      <div className={`container containerSection`}>
        <div ref={section1}>
          <Mysection
            headline={<AboutUsTitle />}
            goToSectionRef={section2}
            scrollTo={scrollTo}
            showArrow={true}
          />
        </div>

        <div ref={section2}>
          <Mysection
            headline={<AboutUsIndex />}
            goToSectionRef={section3}
            scrollTo={scrollTo}
            showArrow={true}
          />
        </div>

        <div ref={section3}>
          <Mysection
            headline={<AboutUsValores />}
            goToSectionRef={section4}
            scrollTo={scrollTo}
            showArrow={true}
          />
        </div>

        <div ref={section4}>
          <Mysection
            headline={<AboutUsPromise />}
            goToSectionRef={section5}
            scrollTo={scrollTo}
            showArrow={true}
          />
        </div>

        <div ref={section5}>
          <Mysection
            headline={<AboutUsEquipo />}
            goToSectionRef={section6}
            scrollTo={scrollTo}
            showArrow={true}
          />
        </div>

        <div ref={section6}>
          <Mysection
            headline={
              <AboutUsFooter scrollTo={scrollTo} goToSelectionRef={section1} />
            }
            scrollTo={scrollTo}
            showArrow={true}
          />
        </div>
      </div>
    </div>
  );
}
