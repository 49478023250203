import React, { useState, useEffect } from "react";
import { Navbar, Nav } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "../../assets/logo.svg";
import close from "../../assets/close.svg";
import "./navBar.css";
import emailjs from "emailjs-com";
import Swal from "sweetalert2";
import { useSelector, useDispatch } from "react-redux";
import { changeShowForm } from "../../store/reducers/thunk";
import { Link } from "react-router-dom";
import navBar from "../../assets/ArrowNavBar.svg";
import mapIcon from "../../assets/mapIcon.svg";
import { useLocation } from "react-router-dom";

function enviarEmail(e) {
  const service = "service_70l4uva";
  const template = "template_aij7j29";
  const publicToken = "pWfjS0wJ7dP1XCsMb";
  e.preventDefault();

  emailjs.sendForm(service, template, e.target, publicToken).then((res) => {
    console.log(res);
  });
  for (let i = 0; i < e.target.length; i++) {
    e.target[i].value = "";
  }

  Swal.fire({
    title: "Exitoso!",
    text: "Su mensaje ha sido enviado correctamente!",
    icon: "success",
    confirmButtonText: "Aceptar",
  });

  return true;
}

function NavigationBar({ scrollTo, goToSelectionRef }) {
  const { showForm: footerForm } = useSelector((state) => state.showForm);
  const [showCloseButton, setShowCloseButton] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [telefono, setTelefono] = useState("");
  const location = useLocation();

  const formatNumber = (e) => {
    let inputTelefono = e.target.value;
    inputTelefono = inputTelefono.replace(/[^0-9]/g, ""); // elimina cualquier caracter que no sea un número
    inputTelefono = inputTelefono.substring(0, 10); // asegura que el número tenga 10 dígitos

    // formatea el número de teléfono
    if (inputTelefono.length <= 3) {
      inputTelefono = `(${inputTelefono})`;
    } else if (inputTelefono.length <= 6) {
      inputTelefono = `(${inputTelefono.substring(
        0,
        3
      )}) ${inputTelefono.substring(3)}`;
    } else {
      inputTelefono = `(${inputTelefono.substring(
        0,
        3
      )}) ${inputTelefono.substring(3, 6)}-${inputTelefono.substring(6)}`;
    }

    setTelefono(inputTelefono);
  };

  const handleCloseMenu = () => {
    console.log(footerForm);
    showFromDisable();
    setShowCloseButton(false);
    setShowForm(false);
  };
  const handleOpenMenu = () => {
    setShowCloseButton(true);
  };
  const handleShowForm = () => {
    setShowForm(true);
  };

  const dispatch = useDispatch();
  function showFromDisable() {
    dispatch(changeShowForm(false));
  }

  const goToPortafolio = () => {
    scrollTo(goToSelectionRef);
  };

  useEffect(() => {
    if (footerForm) {
      setShowCloseButton(true);
      setShowForm(true);
    }
  }, [footerForm]);

  return (
    <Navbar className="navbar" variant="dark" expand="collapse">
      <Navbar.Brand href="/">
        <img src={logo} alt="" style={{ paddingLeft: "20px", width: "70%" }} />
      </Navbar.Brand>
      {!showCloseButton && (
        <Navbar.Toggle
          style={{ marginRight: "40px", border: "none" }}
          aria-controls="basic-navbar-nav"
          onClick={handleOpenMenu}
        />
      )}
      {showCloseButton && (
        <div id="basic-navbar-nav">
          <Nav className="mr-auto sidenav sideNavText">
            {
              <Nav.Item className="close-button">
                <Nav.Link onClick={handleCloseMenu}>
                  <img
                    src={close}
                    alt=""
                    style={{ paddingLeft: "20px", width: "75%" }}
                  />
                </Nav.Link>
              </Nav.Item>
            }
            {/* row */}
            <div className="row">
              {/* column one */}
              <div
                className={
                  showForm && window.innerWidth <= 768
                    ? "d-none d-lg-block column options"
                    : "column options"
                }
              >
                <div className="contactHover">
                  {/* opcion "Inicio" */}

                  <Link onClick={handleCloseMenu} to="/" className="optionLink">
                    Inicio <img className="arrowNavBar" alt="" src={navBar} />
                  </Link>

                  {/* opcion "portafolio" */}

                  {location.pathname.includes("home") ||
                  location.pathname === "/" ? (
                    <Link
                      onClick={() => {
                        goToPortafolio();
                        handleCloseMenu();
                      }}
                      className="optionLink"
                    >
                      Portafolio{" "}
                      <img className="arrowNavBar" alt="" src={navBar} />
                    </Link>
                  ) : (
                    <Link className="optionLink" to="/home/portafolio">
                      Portafolio
                    </Link>
                  )}

                  {/* opcion "Empleos" */}

                  <Link
                    onClick={handleCloseMenu}
                    to="/careers"
                    className="optionLink"
                  >
                    Empleos <img className="arrowNavBar" alt="" src={navBar} />
                  </Link>

                  {/* Agregar "Sobre Nosotro" */}

                  <Link
                    onClick={handleCloseMenu}
                    to="/about"
                    className="optionLink"
                  >
                    Sobre Nosotros{" "}
                    <img className="arrowNavBar" alt="" src={navBar} />
                  </Link>

                  {/* opcion "Contactenos" */}

                  <Nav.Link onClick={handleShowForm} className="optionLink">
                    Contáctanos{" "}
                    <img className="arrowNavBar" alt="" src={navBar} />
                  </Nav.Link>

                  {/* opcion "mapa" */}

                  <Link
                    onClick={handleCloseMenu}
                    to="https://goo.gl/maps/sHnehVLCdqHvbGyH8"
                    className="optionLink"
                  >
                    Mapa <img src={mapIcon} className="mapIcon" alt="" />
                    <img className="arrowNavBar" alt="" src={navBar} />
                  </Link>
                </div>
                {/* <Nav.Link href="/acerca">Sobre Nosotros</Nav.Link> */}
                <hr style={{ marginLeft: "10%", marginRight: "40%" }}></hr>
                <br />
                <h6 className="infoContact">
                  Para todas las consultas de nuevos <br />
                  negocios, por favor contáctenos en <br />
                  comunicaciones@penadefillo.com <br />
                  o a través del formulario <br />
                  “Contacto”. <br />
                  <br />
                  Ave. Gustavo M. Ricart #54, Torre Solazar
                  <br />
                  Ensanche Naco, Santo Domingo,
                  <br />
                  República Dominicana
                </h6>
              </div>
              {showForm && (
                // column two
                <div className="column">
                  <br />
                  <form
                    onSubmit={(e) => {
                      if (enviarEmail(e)) {
                        setShowForm(false);
                        setTelefono("");
                      }
                    }}
                    className="formContent"
                  >
                    <h3 className="formTitle">¡Déjanos tus datos!</h3>

                    <input
                      required={true}
                      style={{ marginBottom: "30px", marginTop: "10px" }}
                      className="input"
                      type="text"
                      placeholder="Empresa"
                      id="company"
                      name="company"
                    />

                    <input
                      className="input"
                      type="text"
                      placeholder="Nombre"
                      maxLength={"50"}
                      required={true}
                      id="name"
                      name="name"
                    />

                    <input
                      className="input"
                      type="text"
                      placeholder="Apellido"
                      maxLength={"50"}
                      required={true}
                      id="lastname"
                      name="lastname"
                    />

                    <input
                      className="input"
                      type="email"
                      placeholder="Correo Electrónico"
                      required={true}
                      id="email"
                      name="email"
                    />

                    <input
                      className="input"
                      type="tel"
                      placeholder="Teléfono"
                      pattern="^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$"
                      required={true}
                      id="phonenumber"
                      name="phonenumber"
                      value={telefono}
                      onChange={formatNumber}
                    />

                    <input
                      className="input"
                      type="text"
                      placeholder="Asunto"
                      maxLength={"200"}
                      required={true}
                      id="subject"
                      name="subject"
                    />

                    <textarea
                      className="formTextArea"
                      id="body"
                      placeholder="Descripción"
                      name="body"
                      maxLength={"500"}
                      required={true}
                      rows="4"
                    ></textarea>
                    <br />

                    <button className="buttonForm" type="submit">
                      Enviar
                    </button>
                  </form>
                </div>
              )}
            </div>
          </Nav>
        </div>
      )}
    </Navbar>
  );
}

export default NavigationBar;
