/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./footer.css";
import lineFooter from "../../assets/footerLine.png";
import continueArrow from "../../assets/VectorHablemos.svg";
import { changeShowForm } from "../../store/reducers/thunk";
import { useDispatch } from "react-redux";
import LinkedInIcon from "../../assets/LINKDIN.svg";
import InstagramIcon from "../../assets/instagram.svg";
import YouTubeIcon from "../../assets/youtube.svg";
import PD_G_logo from "../../assets/PD_G_logo.svg";
import adecc from "../../assets/membersLogos/adecc.png";
import amcham from "../../assets/membersLogos/amcham.png";
import cci from "../../assets/membersLogos/cci.png";
import comercioDominicoMexicano from "../../assets/membersLogos/comercio-dominico-mexicana.png";
import comercioEspana from "../../assets/membersLogos/comercio-espana.png";

function Footer({ scrollTo, goToSelectionRef }) {
  const dispatch = useDispatch();
  function showFrom() {
    dispatch(changeShowForm(true));
  }


  return (
    <footer className="mt-auto py-3 bg-black footerColor">
      <div className="footerContainerDown">
        <Container className="footerRow">
          <Row>
            <Col>
              <h2 className="footerTitleText">
                Transformando tus <br />
                ideas en realidad
              </h2>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src={continueArrow} width={"20px"} alt="" />
                <Link
                  className="footerColor textFooterLinkPages"
                  onClick={() => showFrom()}
                >
                  <div style={{ margin: 0 }} className="footerSubTitleText">
                    Hablemos
                  </div>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
        <Container style={{ marginBottom: 20, marginTop: 100 }}>
          <Row>
            <Col>
              <h2 className="membersTitle">Somos miembros</h2>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-around",
                  gap: 20,
                  marginTop: 20,
                }}
                className="membersContainer"
              >
                <img className="memberLogo" src={adecc} alt="" />
                <img className="memberLogo" src={comercioEspana} alt="" />
                <img className="memberLogo" src={amcham} alt="" />
                <img
                  className="memberLogo"
                  src={comercioDominicoMexicano}
                  alt=""
                />
                <img className="memberLogo" src={cci} alt="" />
              </div>
            </Col>
          </Row>
        </Container>

        <div className="lineFooter">
          <img src={lineFooter} width="100%" alt="" />
        </div>

        <Container className="footerContainerPadding">
          <Row className="footerContainerPaddingTD">
            <Col xs={12} md={4}>
              <div className="textFooterLinkSocialPages">
                <div className="textFooterTitleSocialPages">¡SIGUENOS! </div>

                <a
                  className="footerColor textFooterContentSocialPages"
                  href="https://www.linkedin.com/company/grupopenadefillo/"
                >
                  LINKEDIN
                </a>
                <br />
                <a
                  className="footerColor textFooterContentSocialPages"
                  href="https://www.instagram.com/pena.defillo/"
                >
                  INSTAGRAM
                </a>
                <br />
                <a
                  className="footerColor textFooterContentSocialPages"
                  href="https://www.youtube.com/@grupopenadefillo"
                >
                  YOUTUBE
                </a>
              </div>
            </Col>
            <Col
              className="footerNav"
              xs={12}
              md={8}
              style={{ textAlign: "right" }}
            >
              <img className="PD_G_logo" src={PD_G_logo} alt="" />

              <Link
                className="footerColor textFooterLinkPages"
                onClick={() => scrollTo(goToSelectionRef)}
              >
                PORTAFOLIO
              </Link>
              <Link className="footerColor textFooterLinkPages" to="/careers">
                {""}
                EMPLEOS{" "}
              </Link>
              <Link className="footerColor textFooterLinkPages" to="/about">
                {""}SOBRE NOSOTROS{" "}
              </Link>
              <Link
                className="footerColor textFooterLinkPages"
                onClick={() => showFrom()}
              >
                {" "}
                CONTÁCTANOS{" "}
              </Link>

              <Link
                className="footerColor textFooterLinkPages"
                to="https://goo.gl/maps/sHnehVLCdqHvbGyH8"
                onClick={() => showFrom()}
              >
                {" "}
                UBICACIÓN{" "}
              </Link>
            </Col>
          </Row>
          <Row>
            <Col className="text-center">
              <hr></hr>
              <div className="bottomFooterContent">
                <div>
                  <a href="https://www.linkedin.com/company/grupopenadefillo/">
                    <img className="socialIcon" src={LinkedInIcon} alt="" />
                  </a>

                  <a href="https://www.instagram.com/pena.defillo/">
                    <img className="socialIcon" src={InstagramIcon} alt="" />
                  </a>
                  <a href="https://www.youtube.com/@grupopenadefillo">
                    <img className="socialIcon" src={YouTubeIcon} alt="" />
                  </a>
                </div>
                <div className="dFooter">
                  <p className="copyRight">
                    &copy; Peña Defilló {new Date().getFullYear()}{" "}
                  </p>
                  <p className="dot"> • </p>
                  <li>
                    <a
                      style={{ cursor: "pointer" }}
                      href="https://penadefillo.com/static/media/privacypolicy-GPD.96f1947b3d48aa7a020e.pdf"
                    >
                      Politica de Privacidad
                    </a>
                  </li>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </footer>
  );
}

export default Footer;
