import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import aUP1Line from "../../../assets/aUP1Vector.svg";
import "./aboutUsTitle.css";

function AboutUsTitle() {
  return (
    <div className="aUTBackGround">
      <Container>
        <Row className="justify-content-md-center">
          <Col>
            <h1 className="T1">
              <span className="T1_1">CONOCE </span>
              <span className="T1_2">A GRUPO</span>
              <br />
              <span className="T1_3">
                PEÑA DEFILLÓ <br />{" "}
              </span>
            </h1>

            <img src={aUP1Line} className="aUP1Line" alt="" />

            <br />

            <p className="T2">Nos alegra que estés aquí</p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default AboutUsTitle;
