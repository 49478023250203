import React, { useRef, useEffect } from "react";
import Vimeo from "@u-wave/react-vimeo";
import "./portafolio.css";
import Mysection from "../molecules/mysection";

const videos = [
  {
    id: "847356465",
    loop: true,
    muted: true,
    autoplay: true,
    controls: false,
    disablePictureInPicture: true,
    pretitle: "Premio",
    title: "Effie Awards 2023",
    description:
      "Por tercera vez consecutiva, somos la agencia red más efectiva de República Dominicana.",
  },
  {
    id: "822191203",
    loop: true,
    muted: true,
    autoplay: true,
    controls: false,
    disablePictureInPicture: true,
    pretitle: "Trabajo",
    title: "CND | Gran Casting Soberanos",
    description: "Jovenes convirtiéndose en nuevos Soberanos.",
  },
  {
    id: "822191671",
    muted: true,
    loop: true,
    autoplay: true,
    controls: false,
    disablePictureInPicture: true,
    pretitle: "Trabajo",
    title: "Claro | Gaming Warriors",
    description: "El torneo de E-Sports más grande de República Dominicana.",
  },
  {
    id: "822194051",
    loop: true,
    muted: true,
    autoplay: true,
    controls: false,
    disablePictureInPicture: true,
    pretitle: "Trabajo",
    title: "Nestlé | Toma MÁS",
    description: "Desde hoy toma MÁS Impulso®",
  },
  {
    id: "822194093",
    loop: true,
    muted: true,
    autoplay: true,
    controls: false,
    disablePictureInPicture: true,
    pretitle: "Trabajo",
    title: "BHD | Feria Fleximóvil 2021",
    description: "Un nuevo bebé lo cambia todo.",
  },
];

const VideoOrImage = ({ video }) => {
  return video.id ? (
    <Vimeo video={video.id} {...video} />
  ) : video.image ? (
    <img src={video.image} alt="Effie Awards 2023" className="effie-image" />
  ) : null;
};

const Portafolio = ({ nextSection }) => {
  const videoRefs = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setTimeout(() => {
              const iframe = entry.target.querySelector("iframe");
              if (iframe) {
                iframe.contentWindow.postMessage('{"method":"play"}', "*");
              }
            }, 0);
          } else {
            const iframe = entry.target.querySelector("iframe");
            if (iframe) {
              iframe.contentWindow.postMessage('{"method":"pause"}', "*");
            }
          }
        });
      },
      { threshold: 0 }
    );

    videoRefs.current.forEach((videoRef) => {
      observer.observe(videoRef);
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <>
      {videos.map((video, index) => (
        <div key={video.id}>
          <Mysection
            headline={
              <div className="video-list">
                <div
                  className="video-wrapper"
                  ref={(el) => (videoRefs.current[index] = el)}
                >
                  <VideoOrImage video={video} />

                  <div className="videoTextContainer">
                    <h6 className="preTitleVideo">{video.pretitle}</h6>
                    <h6 className="titleVideo">{video.title}</h6>
                    <h6 className="descriptionVideo">{video.description}</h6>
                  </div>
                </div>
              </div>
            }
            goToSectionRef={""}
            scrollTo={nextSection}
            showArrow={true}
          />
        </div>
      ))}
    </>
  );
};

export default Portafolio;
