import { createSlice } from "@reduxjs/toolkit";

export const showFormSlice = createSlice({
  name: "showForm",
  initialState: {
    showForm: false,
  },
  reducers: {
    setShowForm: (state, { payload }) => {
      state.showForm = payload;
    },
  },
});

export const { setShowForm } = showFormSlice.actions;
