import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import "./carrersDetail.css";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";

function DetailCarrers({ scrollTo, goToSelectionRef }) {
  const [empleos, setEmpleos] = useState([]);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();

  useEffect(() => {
    setLoading(true); // establece loading en true antes de hacer la llamada a fetch
    fetch(`https://cms.pruebagpd.com/api/empleos/${id}`)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.data) {
          setEmpleos([data.data]);
        }
      })
      .catch((error) => console.log(error))
      .finally(() => setLoading(false)); // establece loading en false después de actualizar el estado
  }, [id]);

  return (
    <div className="scrollDetails">
      {loading ? ( // muestra una animación de carga si loading es true
        <div>Cargando...</div>
      ) : (
        // muestra los detalles del empleo si loading es false y empleos no está vacío
        empleos.length > 0 && (
          <div className="carrersFontDetail richText">
            <h6 className="carrersPreTitle">Empleos</h6>
            <h1 className="CarrersTitleDetail">
              <ReactMarkdown>{empleos[0].attributes.Titulo}</ReactMarkdown>
            </h1>

            <span className="carrersTitleDescriptionDetail">Sobre el Rol</span>
            <br />
            <p className="carrersParagraphDetails">
              <ReactMarkdown
                rehypePlugins={[rehypeRaw]}
                remarkPlugins={[remarkGfm]}
              >
                {empleos[0].attributes.Sobre_El_Rol}
              </ReactMarkdown>
            </p>
            <br />
            <br />
            <span className="richText carrersTitleDescriptionDetail">
              Responsabilidades
            </span>
            <br />
            <p className="carrersDescriptionLine">
              <ReactMarkdown
                rehypePlugins={[rehypeRaw]}
                remarkPlugins={[remarkGfm]}
              >
                {empleos[0].attributes.Responsabilidades}
              </ReactMarkdown>
            </p>

            <br />
            <br />
            <span className="carrersTitleDescriptionDetail">Sobre ti</span>
            <br />

            <p className="carrersParagraphDetails">
              <br />
              <ReactMarkdown
                rehypePlugins={[rehypeRaw]}
                remarkPlugins={[remarkGfm]}
              >
                {empleos[0].attributes.Sobre_Ti}
              </ReactMarkdown>
            </p>
            <br />
            <br />
            <span className="carrersTitleDescriptionDetail">
              Algunos de nuestros beneficios
            </span>
            <br />
            <p className="carrersDescriptionLine">
              <br />
              <ReactMarkdown
                rehypePlugins={[rehypeRaw]}
                remarkPlugins={[remarkGfm]}
              >
                {empleos[0].attributes.Beneficios}
              </ReactMarkdown>
            </p>

            <br />
            <br />

            <span className="carrersTitleDescriptionDetail">
              Sobre nosotros
            </span>
            <br />
            <p className="carrersParagraphDetails">
              Somos la empresa líder en la creación de propuestas de valor que
              generan energía y dinamismo en los mercados y la sociedad. Nuestro
              talento comprometido en la generación de soluciones inusuales y de
              alto impacto inspira un cambio en las personas y genera valor para
              nuestros clientes. ¡Únete a nosotros y descubre cómo podemos
              marcar la diferencia juntos!
            </p>
            <br />
          </div>
        )
      )}
      <div className="carrersDetailButton">
        <Button
          className=" carrearButtonHome"
          onClick={() => scrollTo(goToSelectionRef)}
        >
          Aplicar
        </Button>
      </div>
    </div>
  );
}

export default DetailCarrers;
