import Mysection from "../molecules/mysection";
import { useRef } from "react";
import Detail from "../atoms/carrersDetail";
import FormularioCarrers from "../atoms/carrersForm";

export default function Details() {
  const section1 = useRef();
  const section2 = useRef();

  function scrollTo(section) {
    section.current.scrollIntoView({ behavior: "smooth" });
  }

  return (
    <div className="App">
      <div className={`container containerSection`}>
        <div ref={section1}>
          <Mysection
            headline={
              <Detail scrollTo={scrollTo} goToSelectionRef={section2} />
            }
            goToSectionRef={section2}
            scrollTo={scrollTo}
            showArrow={true}
          />
        </div>
        <div ref={section2}>
          <Mysection
            headline={<FormularioCarrers />}
            scrollTo={scrollTo}
            showArrow={true}
          />
        </div>
      </div>
    </div>
  );
}
