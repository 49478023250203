import { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import AWS from "aws-sdk";
import "./carrersForm.css";
import Swal from "sweetalert2";
import { formatPhoneNumber } from "../../utils/phoneFormat";
import emailjs from "emailjs-com";
import axios from "axios";

const CarrersForm = () => {
  const S3_BUCKET = "gpd-hhrr-cv";
  const REGION = "us-east-1";

  AWS.config.update({
    accessKeyId: "AKIARO6VSHBGHAE7332I",
    secretAccessKey: "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",
  });

  const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });

  const [nombres, setNombres] = useState("");
  const [apellidos, setApellidos] = useState("");
  const [email, setEmail] = useState("");
  const [telefono, setTelefono] = useState("");
  const [direccion, setDireccion] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [portafolio, setPortafolio] = useState("");
  const [vacanteAplicada, setVacanteAplicada] = useState("");
  const [salario, setSalario] = useState("");
  const inputRef = useRef(null);

  const [empleos, setEmpleos] = useState([]);
  const [setLoading] = useState(true);
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [isFormVisible, setIsFormVisible] = useState(true);

  const navigate = useNavigate();

  const handleTelefonoChange = (event) => {
    const formattedPhoneNumber = formatPhoneNumber(event.target.value);
    setTelefono(formattedPhoneNumber);
  };

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_epibgjb",
        "template_kaxkp6a",
        form.current,
        "ZcTKOTqW36BRPSl9y"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  // Para email de notificación de solicitud (comunicaciones@penadefillo.com)

  const sendEmail_2 = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_epibgjb",
        "template_3my9xkt",
        form.current,
        "ZcTKOTqW36BRPSl9y"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  var fechaActual = new Date();

  var dia = fechaActual.getDate();
  var mes = fechaActual.getMonth() + 1; // Los meses van de 0 a 11, por lo que se suma 1
  var año = fechaActual.getFullYear();

  var fechaFormateada = dia + "/" + mes + "/" + año;

  useEffect(() => {
    setLoading(true); // establece loading en true antes de hacer la llamada a fetch
    fetch(`https://cms.pruebagpd.com/api/empleos/${id}`)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.data) {
          setEmpleos([data.data]);
        }
      })
      .catch((error) => console.log(error))
      .finally(() => setLoading(false)); // establece loading en false después de actualizar el estado
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const fileByID = () => {
    return `${nombres}-${apellidos}-${email.split("@")[0]}-${telefono.slice(
      -4
    )}`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setIsFormVisible(false);
    sendEmail(e);
    sendEmail_2(e);
    setVacanteAplicada(empleos[0].attributes.Titulo);

    try {
      await uploadFile(selectedFile, fileByID()).then((upf) => {
        const data = {
          data: {
            Nombres: nombres,
            Apellidos: apellidos,
            Email: email,
            Telefono: telefono,
            Direccion: direccion,
            Linkedin: linkedin,
            Portafolio: portafolio,
            Resume: `https://gpd-hhrr-cv.s3.amazonaws.com/${fileByID()}.${upf}`,
            Vacante_Aplicada: empleos[0].attributes.Titulo,
            vacante: vacanteAplicada,
            fecha: fechaFormateada,
            salario: salario,
          },
        };

        // Make API request
        console.log(data);
        const url = "https://cms.pruebagpd.com/api/banco-de-talentos";
        fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        })
          .then((response) => response.json())

          .then(async (responseData) => {
            setTimeout(() => {
              setIsLoading(false);
              setIsFormVisible(true);

              //ID
              Swal.fire({
                title: "¡Gracias por aplicar!",
                text: "Tu solicitud ha sido enviada.",
                icon: "success",
                confirmButtonText: "Ok",
              }).then(() => {
                navigate("/careers", { replace: true });
                // Limpiar los campos del formulario
                setNombres("");
                setApellidos("");
                setEmail("");
                setTelefono("");
                setDireccion("");
                setLinkedin("");
                setPortafolio("");
                setSelectedFile("");
                setSalario("");
                inputRef.current.value = null;
              });
            }, 2000);
            //Subiendo archivo
          })
          .catch((error) => {
            Swal.fire({
              title: "¡Error al subir archivo!",
              text: "Intente nuevamente.",
              icon: "error",
              confirmButtonText: "Ok",
            });
          });
      });
    } catch (error) {
      console.log("Error:", error);
    }
  };

  //s3
  const [progress, setProgress] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileInput = (e) => {
    setSelectedFile(e.target.files[0]);

    const file = e.target.files[0];

    if (file) {
      // Crea un nuevo objeto FormData
      const formData = new FormData();
      formData.append("file", file);

      // Calcula y actualiza el progreso mientras el archivo se carga
      const config = {
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setProgress(percentCompleted);
        },
      };

      axios
        .post("/your-upload-url", formData, config)
        .then((response) => {
          // Maneja la respuesta si necesario
        })
        .catch((error) => {
          // en caso de error
          console.error("Error uploading file:", error);
        });
    }
  };

  const uploadFile = async (file, fileName) => {
    return new Promise((resolve, reject) => {
      const ext = file.name.substr(file.name.lastIndexOf(".") + 1);
      const params = {
        ACL: "public-read",
        Body: file,
        Bucket: S3_BUCKET,
        Key: `${fileName}.${ext}`,
      };

      const request = myBucket.putObject(params);
      request.on("httpUploadProgress", (evt) => {
        const p = Math.round((evt.loaded / evt.total) * 100);
        setProgress(p);
        resolve(ext);
      });

      request.send((err) => {
        if (err) {
          console.log(err);
          reject(err);
        }
      });
    });
  };

  return (
    <div className="mainContainer">
      <div>
        <Container className="formContainer">
          <Row className="d-flex justify-content-between align-items-center">
            <Col>
              <h2 className="formCarrersTitle">Aplica a este puesto.</h2>
            </Col>
            <Col className="formCarrersTitleRequired">
              <span className="formCarrersRequired">*</span> Obligatorio
            </Col>
          </Row>
          {isLoading && (
            <div className="lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          )}
          {isFormVisible && (
            <Form onSubmit={handleSubmit} ref={form}>
              <Row>
                <Col md={12} lg={6} xl={6}>
                  <Form.Group controlId="nombres">
                    <Form.Label className="formCarrearLabels">
                      Nombres <span className="formCarrersRequired">*</span>
                    </Form.Label>
                    <Form.Control
                      required
                      className="formCarrearsInputs"
                      type="text"
                      name="nombre"
                      value={nombres}
                      onChange={(e) => setNombres(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group controlId="apellidos">
                    <Form.Label className="formCarrearLabels">
                      Apellidos <span className="formCarrersRequired">*</span>
                    </Form.Label>
                    <Form.Control
                      required
                      className="formCarrearsInputs"
                      type="text"
                      name="apellido"
                      value={apellidos}
                      onChange={(e) => setApellidos(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group controlId="email">
                    <Form.Label className="formCarrearLabels">
                      Correo Electrónico{" "}
                      <span className="formCarrersRequired">*</span>
                    </Form.Label>
                    <Form.Control
                      required
                      className="formCarrearsInputs"
                      type="email"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Form.Group>

                  {/* resumen */}

                  <Form.Group controlId="salario">
                    <Form.Label className="formCarrearLabels">
                      Salario Actual o Ultimo Salario
                    </Form.Label>
                    <Form.Control
                      className="formCarrearsInputs"
                      type="text"
                      name="salario"
                      value={salario}
                      onChange={(e) => setSalario(e.target.value)}
                    />
                  </Form.Group>
                </Col>

                <Col md={12} lg={6} xl={6}>
                  <Form.Group controlId="vacanteAplicada">
                    <Form.Control
                      type="text"
                      value={vacanteAplicada}
                      name="vacanteAplicada"
                      onChange={(e) => setVacanteAplicada(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group controlId="direccion">
                    <Form.Label className="formCarrearLabels">
                      Dirección <span className="formCarrersRequired">*</span>
                    </Form.Label>
                    <Form.Control
                      required
                      className="formCarrearsInputs"
                      type="text"
                      value={direccion}
                      onChange={(e) => setDireccion(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group controlId="telefono">
                    <Form.Label className="formCarrearLabels">
                      Número Telefónico{" "}
                      <span className="formCarrersRequired">*</span>
                    </Form.Label>
                    <Form.Control
                      required
                      className="formCarrearsInputs"
                      type="tel"
                      name="telefono"
                      value={telefono}
                      onChange={handleTelefonoChange}
                    />
                  </Form.Group>

                  <Form.Group controlId="resume">
                    <Form.Label className="formCarrearLabels">
                      Resume/CV {progress}%
                      <span className="formCarrersRequired"> *</span>
                    </Form.Label>
                    <Form.Control
                      // accept=".pdf, .jpg, .docx, .doc"
                      required
                      ref={inputRef}
                      className="custom-file-input"
                      type="file"
                      onChange={handleFileInput}
                    />
                  </Form.Group>
                </Col>
                <div className="divider">
                  <hr style={{ width: "100%" }} />
                </div>
                <Col md={12} lg={6} xl={6}>
                  <Form.Group controlId="linkedin">
                    <Form.Label className="formCarrearLabels">
                      Perfil de LinkedIn{" "}
                    </Form.Label>
                    <Form.Control
                      className="formCarrearsInputs"
                      type="text"
                      value={linkedin}
                      name="linkedin"
                      onChange={(e) => setLinkedin(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col md={12} lg={6}>
                  <Form.Group controlId="portafolio">
                    <Form.Label className="formCarrearLabels">
                      Página Web/Portafolios
                    </Form.Label>
                    <Form.Control
                      className="formCarrearsInputs"
                      type="text"
                      value={portafolio}
                      name="portafolio"
                      onChange={(e) => setPortafolio(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group controlId="fecha">
                    <Form.Control
                      type="hidden"
                      value={fechaFormateada}
                      name="date"
                    />
                  </Form.Group>

                  <Form.Group controlId="vacante">
                    <Form.Control
                      type="hidden"
                      value={
                        empleos.length > 0 ? empleos[0].attributes.Titulo : ""
                      }
                      name="vacante"
                    />
                  </Form.Group>
                </Col>
                <div className="buttonContainer">
                  <Button
                    className="formCarrersButton"
                    variant="primary"
                    type="submit"
                  >
                    Enviar aplicación
                  </Button>
                </div>
              </Row>
            </Form>
          )}
        </Container>
      </div>
    </div>
  );
};
export default CarrersForm;
