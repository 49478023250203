import React from "react";
import "./carrersHome2.css";
import image1 from "../../assets/job1.png";
import image2 from "../../assets/job2.png";
import light from "../../assets/VectorlightHome.png";
import heart from "../../assets/VectorheartHome.png";
function carrersHome() {
  return (
    <>
      <div className="textHomeColor2">
        <div className="backgroundCarrersHome2 textHome2">
          <h1 className="textTitleHome2">¿POR QUÉ UNIRTE A NOSOTROS?</h1>

          <div className="homeCarrearContentLeft">
            <img className="imageHomeLeft" src={image1} width="55%" alt="" />
            <span className="textHomeLeft">
              <img src={light} width="15px" alt="" />
              <h2>Sobre Grupo Peña Defilló</h2>
              Grupo Peña Defilló es el grupo de comunicaciones más <br />
              grande de República Dominicana. Conocido por liderar y <br />
              aportar a grandes cambios en la industria publicitaria <br />{" "}
              local y de la región del Caribe, con años de experiencia <br />
              en contenido, media y digital.
            </span>
          </div>

          <div className="homeCarrearContentRight">
            <span className="textHomeRight">
              <img src={heart} width="15px" alt="" />
              <h2>Nuestro propósito</h2>
              Creamos conexiones innovadoras para impactar de <br/>
              manera significativa la vida de las personas, a través de <br/>
              soluciones de comunicación que dan valor a las marcas.
            </span>
            <img className="imageHomeRight" src={image2} width="55%" alt="" />
          </div>
        </div>
      </div>

    </>
  );
}

export default carrersHome;
