import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./aboutUsValores.css";
import diamond from "./../../../assets/aUV_diamond.svg";

function AboutUsValores() {
  return (
    <div className="aboutUsValoresBackGround">
      <Container fluid>
        <Row className="justify-content-md-center">
          <Col className="aUVContent" md={5}>
            <div className="aUVTitle">
              <img src={diamond} className="aUVTitleImage" alt="" />
              <h1 className="aUVTitleText">Valores</h1>
              <br />
            </div>
            <p className="aUVText">
              <span className="aUVSubTitle">Generadores de conexiones</span>
              <br />
              <span className="aUVDetails">
                La cooperación empática, comunicación activa y centralización en
                el cliente son fundamentales para generar conexiones. Al igual
                que la madurez emocional y generosidad social son importantes
                para mantener esas conexiones de forma positiva.
              </span>
              <br />
              <span className="aUVSubTitle">Valentía transformadora</span>
              <br />
              <span className="aUVDetails">
                Nuestro espíritu transformador, honor y evolución constante son
                valores claves para el éxito empresarial. Con estos valores como
                guía, podemos lograr cualquier objetivo y superar cualquier
                obstáculo en el camino.
              </span>
              <br />
              <span className="aUVSubTitle">Jugar con propósito</span>
              <br />
              <span className="aUVDetails">
                En nuestra empresa, sabemos que la atención al detalle,
                productividad y la conexión con nuestros clientes son
                fundamentales para alcanzar el éxito. Por eso, nos esforzamos
                por mantener una energía contagiosa que nos permite trabajar con
                propósito, manteniendo siempre la motivación y el entusiasmo.
              </span>
              <br />
              <span className="aUVSubTitle">Exploradores de pensamiento</span>
              <br />
              <span className="aUVDetails">
                La apertura al aprendizaje y la diversidad, junto con el
                conocimiento sistemático y la curiosidad permanente, son la
                clave para la innovación pragmática y una visión holística.
                Juntos, estos valores fomentan una cultura de aprendizaje y
                mejora continua que puede marcar la diferencia en cualquier
                organización.
              </span>
            </p>
          </Col>
        </Row>
      </Container>
      <div className="aUVContent"></div>
    </div>
  );
}
export default AboutUsValores;
