import React from "react";
import "./homeContent.css";
import flecha from "../../assets/Elipse.png";
import { Button } from "react-bootstrap";

import { useMediaQuery } from "@mui/material";

function AnimatedText({ scrollTo, goToSelectionRef, goToPortafolioRef }) {
  const isSmallScreen = useMediaQuery("(max-width: 768px)");

  const videoSrc = isSmallScreen
    ? "https://ab53efda85cfc.s3.amazonaws.com/grupo-pena-defillo/Digital-Atronauta-compress-low-mobile.mp4"
    : "https://ab53efda85cfc.s3.amazonaws.com/grupo-pena-defillo/Digital-Atronauta-compress-low.mp4";

  return (
    <div>
      <div className="container-home gradient-video">
        <video autoPlay muted loop className="video-size-home">
          <source src={videoSrc} type="video/mp4" />
          Tu navegador no admite la reproducción de video.
        </video>

        <div className="text-contents">
          <div className="title-homeContent h2-font-home">
            SOMOS EL PODER <br /> DE LA UNIÓN
          </div>
          <div className="description-homeContent">
            Somos el grupo número uno de comunicaciones en <br /> República
            Dominicana y el Caribe.
          </div>

          <Button
            className="button-homeContent"
            onClick={() => scrollTo(goToPortafolioRef)}
          >
            Conocer Más
          </Button>
        </div>
      </div>
      <div>
        <div style={{ display: "flex", justifyContent: "start" }}>
          <img
            onClick={() => scrollTo(goToSelectionRef)}
            className="chatbotAnimado icon-container-home"
            src={flecha}
            width="50%"
            alt=""
          />
        </div>
      </div>
    </div>
  );
}

export default AnimatedText;
