import React from "react";
import ChatBot from "react-simple-chatbot";
import chatbotInterior from "../../assets/chatbot-interior.png";

const Chatbot = () => {
  const servicesOptions = [
    { value: 1, label: "Publicidad", trigger: "publicidad" },
    {
      value: 2,
      label: "Estrategia de marca",
      trigger: "Estrategia_de _marca",
    },
    { value: 3, label: "Contenido de marca", trigger: "Contenido_de_marca" },
    { value: 4, label: "Diseño de marca", trigger: "Diseno_de_marca" },
    {
      value: 5,
      label:
        "CRM (Customer relationship management = Gestión de relaciones con clientes)",
      trigger: "crm",
    },
    {
      value: 6,
      label: "PRM (Gestión de relaciones con socios) & Adquisición de clientes",
      trigger: "prm",
    },
    {
      value: 7,
      label: "Inteligencia de clientes y datos",
      trigger: "inteligencia_clientes",
    },
    { value: 8, label: "Análisis de clientes", trigger: "analisis_clientes" },
    { value: 9, label: "Gestión de Datos", trigger: "gestion_datos" },
    { value: 10, label: "Marketing Digital", trigger: "marketing_digital" },
    { value: 11, label: "Producción Digital", trigger: "produccion_digital" },
    { value: 12, label: "Marketing Directo", trigger: "marketing_directo" },
    {
      value: 13,
      label: "Comercio Electrónico y Móvil",
      trigger: "comercio_electronico",
    },
    {
      value: 14,
      label: "Marketing Experimental",
      trigger: "marketing_experimental",
    },
    { value: 15, label: "Marketing Integrado", trigger: "marketing_integrado" },
    { value: 16, label: "Marketing Móvil", trigger: "marketing_movil" },
    {
      value: 17,
      label: "Marketing Promocional",
      trigger: "marketing_promocional",
    },
    { value: 18, label: "Redes Sociales", trigger: "redes_sociales" },
    { value: 19, label: "Marketing de Compras", trigger: "marketing_compras" },
    { value: 20, label: "Programas de Lealtad", trigger: "programas_lealtad" },
    { value: 21, label: "Páginas Web", trigger: "paginas_web" },
  ];

  return (
    <div className="prueba">
      <ChatBot
        bubbleStyle={{
          backgroundColor: "#212529",
          color: "white",
          maxWidth: "100%",
        }}
        bubbleOptionStyle={{ backgroundColor: "#212529", color: "white" }}
        hideSubmitButton="true"
        placeholder=""
        botAvatar={chatbotInterior}
        steps={[
          {
            id: "1",
            message:
              "¡Hola, bienvenido a Grupo Peña Defilló! 😄 ¿Cómo puedo apoyarte hoy?",
            trigger: "2",
          },
          {
            id: "2",
            options: [
              { value: 1, label: "Servicios", trigger: "servicios" },
              { value: 2, label: "¿Quiénes somos?", trigger: "quienes_somos" },
              { value: 3, label: "Contacto", trigger: "contacto" },
              { value: 4, label: "Ubicación", trigger: "ubicacion" },
            ],
          },
          {
            id: "3",
            message: "¿Puedo apoyarte con algo más?",
            trigger: "2",
          },
          {
            id: "servicios",
            message: "En Grupo Peña Defillo te ofrecemos estos servicios:",
            trigger: "servicios_options",
          },
          {
            id: "quienes_somos",
            message:
              "Somos el grupo de comunicaciones más grande de República Dominicana. Conocido por liderar y aportar a grandes cambios en la industria publicitaria local y de la región del Caribe, con años de experiencia en contenido, media y digital. Somos creadores de soluciones efectivas y de alto impacto, con talento comprometido en la generación de valor para nuestros clientes.",
            trigger: "3",
          },
          {
            id: "contacto",
            message:
              "Para nuevos servicios o negocios puedes contactarnos al +1 (829) 763-5773 o por correo en comunicaciones@penadefillo.com, lunes a viernes de 8:30am a 6:00pm.",
            trigger: "3",
          },
          {
            id: "ubicacion",

            component: (
              <div>
                <div>
                  {" "}
                  Puede visitarnos en Ave. Gustavo M. Ricart #54, Torre Solazar
                  piso 9, Ensanche Naco, Santo Domingo, República Dominicana.
                  Para obtener las indicaciones de como llegar, haga clic&nbsp;
                  <a href="https://goo.gl/maps/AUbT9WM8UwN1Moyi8">aquí.</a>
                </div>
              </div>
            ),
            trigger: "3",
          },
          {
            id: "nuevos_negocios",
            message:
              "Para nuevos servicios o negocios puedes contactarnos al +1 (829) 763-5773 o por correo en comunicaciones@penadefillo.com, lunes a viernes de 8:30am a 6:00pm.",
            trigger: "3",
          },
          {
            id: "servicios_options",
            options: servicesOptions,
          },
          {
            id: "publicidad",
            message:
              "Sea cual sea su negocio, el propósito de nuestros talentosos planificadores estratégicos y equipos de creatividad es hacer de tu marca los activos que te permitan liderar el cambio.",
            trigger: "nuevos_negocios",
          },

          {
            id: "Estrategia_de _marca",
            message:
              "La estrategia de marca es el punto de partida para todos nuestros clientes. Sin embargo, para nosotros no es suficiente volverlos líderes, queremos sostener su liderazgo y hacer que nuestros clientes “Lideren el Cambio”. Es por eso que reunimos a los mejores equipos de planificadores estratégicos en todo el mundo.            ",
            trigger: "nuevos_negocios",
          },

          {
            id: "Contenido_de_marca",
            message:
              "El contenido de marca no solo informa y entretiene a tus clientes, sino que fortalecerá su relación con la marca. Sea cual sea el tipo de contenido que tu estrategia de marca necesite, lo podemos crear desde aplicaciones sociales digitales hasta narración de historias.",
            trigger: "nuevos_negocios",
          },

          {
            id: "Diseno_de_marca",
            message:
              "Todo empieza con una estrategia de marca codificada que establece su concepto de marca. Luego llegan los valores blandos, lo que la gente ve, siente y experimenta con tu marca: la identidad de la marca, marca digital, venta al por menor y el empaquetado. ¿Mencionamos que tenemos a los mejores diseñadores preparados para darle a su marca un aspecto e identidad única?",
            trigger: "nuevos_negocios",
          },

          {
            id: "crm",
            message:
              "La comunicación digital ha cambiado la forma en que sus clientes se conectan con su marca, a un diálogo continuo. Desarrollamos la estrategia de CRM, el ecosistema, el canal y planes de contacto, para así elevar el valor de sus clientes aumentando su satisfacción y potencial.",
            trigger: "nuevos_negocios",
          },
          {
            id: "prm",
            message:
              "La delgada línea entre los prospectos y clientes se está volviendo cada vez más borrosa. Las personas se van a conectar con su marca mucho antes de que alguna compra suceda. El uso de estos conocimientos nos permitirá impulsar el mensaje correcto en el momento adecuado para convertir a sus prospectos en sus clientes.",
            trigger: "nuevos_negocios",
          },
          {
            id: "inteligencia_clientes",
            message:
              "El cliente conectado genera cantidades masivas de datos. Tenemos la habilidad de rastrear, estructurar y extraer estos datos para mejorar y ayudarte a entender a tus clientes, generar ideas y usar cada punto de contacto de marketing con el máximo efecto.",
            trigger: "nuevos_negocios",
          },
          {
            id: "analisis_clientes",
            message:
              "Conozca a sus visitantes para impulsar su comprensión de los clientes potenciales y la eficacia de su marketing. Innumerables interacciones de los clientes con su marca generan datos sobre sus múltiples puntos de contacto digitales, en particular los propios (marca, dispositivos móviles y micrositios, aplicaciones…). Convertimos esos datos en conocimientos para impulsar su comprensión de los usuarios y la eficacia de su marketing.",
            trigger: "nuevos_negocios",
          },
          {
            id: "gestion_datos",
            message:
              "Desatar todo el potencial del marketing y los datos de los clientes implica la capacidad de estructurarlos e ir más allá de los silos tradicionales: nosotros construimos las plataformas de gestión de datos de clientes (DMP) capaces de estructurar a una visión única de su cliente y conducir su ROI de marketing a nuevas alturas.",
            trigger: "nuevos_negocios",
          },
          {
            id: "marketing_digital",
            message:
              "Lo digital no es un canal, es un mundo hecho de múltiples puntos de contacto. Desde la publicidad online y móvil hasta redes sociales, te ayudamos a definir las herramientas digitales más eficientes para tus necesidades. Y la mejor forma de organizarlas, tomando en cuenta el viaje del consumidor y las ideas.",
            trigger: "nuevos_negocios",
          },
          {
            id: "produccion_digital",
            message:
              "Cuando se trata de proyectos digitales, es esencial combinar la mejor estrategia y las ideas creativas con la tecnología que coincida con sus necesidades y limitaciones. En tiempo, en presupuesto y en calidad.",
            trigger: "nuevos_negocios",
          },
          {
            id: "marketing_directo",
            message:
              "El diálogo uno a uno con sus prospectos y clientes toma varias formas. Nos aseguramos de que los remitentes, correos electrónicos, móviles y programas telefónicos trabajen juntos para llegar a la persona correcta con el mensaje correcto en el momento adecuado.",
            trigger: "nuevos_negocios",
          },
          {
            id: "comercio_electronico",
            message:
              "Para cualquier minorista y cada marca, el comercio digital se está convirtiendo en un canal de transacción cada vez más importante. Diseñamos las estrategias, la experiencia de usuarios e implementamos las soluciones técnicas para que el comercio y las experiencias de marca trabajen juntos para llevar sus ventas a nuevos niveles.",
            trigger: "nuevos_negocios",
          },
          {
            id: "marketing_experimental",
            message:
              "La inmersión total y la interacción física con una marca genera un nivel profundo de compromiso. Creamos eventos o inmersiones de compradores para crear o fortalecer el vínculo entre su marca y sus clientes.",
            trigger: "nuevos_negocios",
          },
          {
            id: "marketing_integrado",
            message:
              "A veces un canal de comunicación es suficiente, otras veces se necesitan muchos más. Dependiendo de tus metas, recomendamos los mejores canales y creamos todos los mensajes acorde a su estrategia de marca y al conocimiento de sus consumidores para ofrecer una arquitectura global eficiente.",
            trigger: "nuevos_negocios",
          },
          {
            id: "marketing_movil",
            message:
              "Los móviles son los dispositivos digitales de mayor difusión. Desarrollamos campañas, apps y programas móviles que ponen tu marca al alcance de tus clientes.",
            trigger: "nuevos_negocios",
          },
          {
            id: "marketing_promocional",
            message:
              "Las promociones de venta y las campañas de activación deben impulsar las ventas de manera que construyan su marca. Convertimos los conocimientos de los clientes en campañas promocionales multicanal para hacer crecer su negocio.",
            trigger: "nuevos_negocios",
          },
          {
            id: "redes_sociales",
            message:
              "Cuando se trata de elegir una marca, los compañeros son la fuente número 1 de recomendaciones y los diversos canales de redes sociales son donde se llevan a cabo estas recomendaciones. Desarrollamos estrategias sociales y las disposiciones globales relacionadas.",
            trigger: "nuevos_negocios",
          },
          {
            id: "marketing_compras",
            message:
              "El punto de venta está experimentando masivas transformaciones, sin embargo, sigue siendo un momento clave de la verdad. Desde la estrategia hacia la implementación, te ayudamos a llevar el tráfico desde el punto de venta, y hacer que tu marca cobre vida en un ambiente minorista, y contribuir a la digitalización de la experiencia de compra.",
            trigger: "nuevos_negocios",
          },
          {
            id: "programas_lealtad",
            message:
              "Aférrate a tus clientes. Una vez adquiridos, querrás impulsar el valor de por vida de tus clientes. Ya sea que sean transaccionales o basados en el compromiso, creamos la estrategia, las reglas, los KPI’s y el plan para mantener a tus clientes felices, incrementar su valor y eventualmente volverlos defensores.",
            trigger: "nuevos_negocios",
          },
          {
            id: "paginas_web",
            message:
              "Desde un sitio web corporativo hasta un sitio web de comercio electrónico, sean cuales sean sus necesidades, le brindamos la recomendación estratégica y creamos exactamente el tipo de sitio web que lo ayudará a alcanzar sus objetivos.",
            trigger: "nuevos_negocios",
          },
        ]}
      />
    </div>
  );
};

export default Chatbot;
