import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./aboutUsIndex.css";
import arrow from "./../../../assets/aUI_Arrow_2.svg";
import elipse from "./../../../assets/FrameAgency.png";

function AboutUsIndex() {
  return (
    <div className="aUIndexBackGround">
      <Container>
        <Row>
          <Col className="aUIndexPart1">
            <img src={elipse} className="aUIP1elipse" alt="" />
          </Col>

          <Col className="aUIndexPart2">
            <h2 className="aUIP1word">
              Somos una agencia creativa trabajando desde República Dominicana y
              el Caribe
              <br />
            </h2>

            <p className="aUIP2List">
              <span className="aUIP2Lnumber">01</span>
              <span className="aUIP2Lname">Nuestro Compromiso </span>
              <span>
                <img className="aUI_arrow" src={arrow} alt="" />
              </span>
              <br />
              <br />
              <span className="aUIP2Lnumber">02</span>
              <span className="aUIP2Lname">Nuestros Valores</span>
              <span>
                <img className="aUI_arrow" src={arrow} alt="" />
              </span>
              <br />
              <br />
              <span className="aUIP2Lnumber">03</span>
              <span className="aUIP2Lname">Nuestra Misión</span>
              <span>
                <img className="aUI_arrow" src={arrow} alt="" />
              </span>
              <br />
              <br />
              <span className="aUIP2Lnumber">04</span>
              <span className="aUIP2Lname">Nuestro Equipo</span>
              <span>
                <img className="aUI_arrow" src={arrow} alt="" />
              </span>
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default AboutUsIndex;
