import AnimatedText from "../atoms/homeContent";
import Mysection from "../molecules/mysection";
import SubHome from "../atoms/subHome";
import SubHome2 from "../atoms/subHome2";
import { useEffect, useRef } from "react";
import Portafolio from "../atoms/portafolio";
import Footer from "../atoms/footer";
import { useParams } from "react-router-dom";
import "./home.css";

export default function Home({ scrollToPortafolio }) {
  const section1 = useRef();
  const section2 = useRef();
  const section3 = useRef();
  const section4 = useRef();
  const section5 = useRef();

  const { section } = useParams();

  function scrollTo(section) {
    section.current.scrollIntoView({ behavior: "smooth" });
  }
  useEffect(() => {
    scrollToPortafolio(section4, scrollTo);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (section === "portafolio") {
      setTimeout(() => {
        scrollTo(section4);
      }, 300);
    }
  }, [section]);

  return (
    <div className="App">
      <div className={`container containerSection`}>
        <div ref={section1}>
          <Mysection
            headline={
              <div className="content contentHomePage">
                <AnimatedText
                  scrollTo={scrollTo}
                  goToSelectionRef={section2}
                  goToPortafolioRef={section4}
                />
              </div>
            }
            goToSectionRef={section2}
            goToPortafolioRef={section4}
            scrollTo={scrollTo}
            showArrow={true}
          />
        </div>
        <div ref={section2}>
          <Mysection
            headline={<SubHome />}
            goToSectionRef={section3}
            scrollTo={scrollTo}
            showArrow={true}
          />
        </div>
        <div ref={section3}>
          <Mysection
            headline={<SubHome2 />}
            goToSectionRef={section4}
            scrollTo={scrollTo}
            showArrow={true}
          />
        </div>

        <div ref={section4}>
          <Portafolio
            nextSection={section5}
            goToSectionRef={section5}
            scrollTo={scrollTo}
          />
        </div>
        <div ref={section5}>
          <Mysection
            headline={
              <Footer scrollTo={scrollTo} goToSelectionRef={section4} />
            }
            scrollTo={scrollTo}
            showArrow={true}
          />
        </div>
      </div>
    </div>
  );
}
