import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./aboutUsPromise.css";

function AboutUsPromise() {
  return (
    <div className="aUPBackGround">
      <Container>
        <Row className="justify-content-md-center">
          <Col md={{ span: 8, offset: 0 }}>
            <div className="aUPContent">
              <h1 className="aUPMision">
                Estamos comprometidos a hacer de{" "}
                <span className="aUPit1">Grupo Peña Defilló</span> un lugar
                donde las personas puedan realizar el{" "}
                <span className="aUPit2">mejor trabajo </span>
                de su carrera de manera sostenible.
              </h1>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default AboutUsPromise;
